var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loaded
    ? _c(
        "div",
        { staticClass: "d-flex justify-content-center mb-3" },
        [_c("ct-centered-spinner")],
        1
      )
    : _c(
        "div",
        [
          _c(
            "b-card",
            [
              _c("b-card-text", [
                _vm.isEligibleForTrial
                  ? _c("div", [
                      _c(
                        "h3",
                        { staticClass: "mt-0" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                `${_vm.product.name} - Free Trial (${_vm.product.trial_duration_days} Days)`
                              ) +
                              "\n          — " +
                              _vm._s(_vm._f("dollars")(_vm.product.price)) +
                              "\n\n          "
                          ),
                          _c("ach-warning", {
                            attrs: { "cart-item": _vm.product },
                          }),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "After " +
                            _vm._s(_vm.product.trial_duration_days) +
                            " days, the price will be adjusted to " +
                            _vm._s(_vm._f("dollars")(_vm.product.price)) +
                            " per month."
                        ),
                      ]),
                    ])
                  : _c(
                      "h3",
                      { staticClass: "mt-0" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.product.name) +
                            " — " +
                            _vm._s(_vm._f("dollars")(_vm.product.price)) +
                            "\n        "
                        ),
                        _c("ach-warning", {
                          attrs: { "cart-item": _vm.product },
                        }),
                      ],
                      1
                    ),
                _vm.product.schema
                  ? _c(
                      "div",
                      [
                        _c("h3", { staticClass: "text-center" }, [
                          _vm._v("\n          Required information\n        "),
                        ]),
                        _c("schema-form", {
                          ref: "productData",
                          attrs: {
                            fields: _vm.product.schema,
                            "show-company-mailing-address-option": true,
                          },
                          model: {
                            value: _vm.productData,
                            callback: function ($$v) {
                              _vm.productData = $$v
                            },
                            expression: "productData",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-1",
                  attrs: {
                    variant: "primary",
                    "aria-label": "add to cart button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showConfirmationModal(_vm.productData)
                    },
                  },
                },
                [_vm._v("\n      Add to Cart\n    ")]
              ),
              _c(
                "confirmation-modal",
                {
                  attrs: {
                    id: _vm.modalId,
                    title: _vm.modalValues?.title,
                    "ok-button-text": _vm.modalValues?.okButtonText,
                    "cancel-button-text": _vm.modalValues?.cancelButtonText,
                  },
                  on: { ok: _vm.ok, cancel: _vm.cancel },
                },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.modalValues?.innerHtml) },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }